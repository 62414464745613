import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import React, { RefObject, useEffect, useRef } from "react";
import { Button } from "../common/Button";
import { HoverIcon } from "../common/HoverIcon";
import { KeyboardShortcuts, Keys } from "../common/KeyboardShortcuts";
import { Api, LazyGridView } from "../common/LazyGridView";
import { TextInput } from "../common/TextInput";
import { Vector } from "../common/Vector";
import { Gallery } from "../models/Gallery";
import { Callback } from "../util/Callback";
import { checkNotNull } from "../util/Nullable";
import "./GalleryPage.css";
import { GalleryTile } from "./GalleryTile";
import { DEFAULT_SORT, Sort } from "./Sort";
import { useHistory } from "react-router-dom";

export function GalleryPage({
    galleries,
    initialScroll,
    onScroll,
    selected,
    onSelect,
    sort,
    onSort,
    filterString,
    onFilter,
}: {
    galleries: Gallery[];
    initialScroll: RefObject<number>;
    onScroll: Callback<number>;
    selected: string;
    onSelect: Callback<string>;
    sort: Sort;
    onSort: Callback<Sort>;
    filterString: string;
    onFilter: Callback<string>;
}) {
    const visible = galleries
        .filter(
            (g) =>
                !g.deleted &&
                g.name
                    .toLocaleLowerCase()
                    .includes(filterString.toLocaleLowerCase())
        )
        .sort(sort);

    useEffect(() => {
        if (
            visible.length > 0 &&
            visible.find((g) => g._id === selected) == null
        ) {
            onSelect(visible[0]._id);
        }
    }, [visible, onSelect, selected]);

    const api = useRef<Api>();

    function offsetSelection(amount: number) {
        const index = visible.findIndex((g) => g._id === selected) + amount;
        const newIndex = Math.min(visible.length - 1, Math.max(index, 0));
        onSelect(visible[newIndex]._id);
        api.current?.scrollTo(newIndex);
    }
    const history = useHistory();
    function onKey(key: Keys) {
        switch (key) {
            case Keys.ArrowLeft:
                offsetSelection(-1);
                return true;
            case Keys.ArrowRight:
                offsetSelection(1);
                return true;
            case Keys.ArrowUp:
                offsetSelection(-checkNotNull(api.current).computeVisible().x);
                return true;
            case Keys.ArrowDown:
                offsetSelection(checkNotNull(api.current).computeVisible().x);
                return true;
            case Keys.Enter:
                history.push(`/galleries/${selected}/view`);
                return true;
            default:
                return false;
        }
    }
    function createShuffleSort(): Sort {
        const order = galleries.map((g) => g._id);
        const len = order.length;

        for (let i = 0; i < len; i++) {
            const pos = i + Math.ceil(Math.random() * (len - i + 1));
            [order[i], order[pos]] = [order[pos], order[i]];
        }

        return (a, b) => order.indexOf(a._id) - order.indexOf(b._id);
    }
    return (
        <div className="gallery-page">
            <div className="gallery-page__controls">
                <Button onClick={() => onSort(DEFAULT_SORT)}>Sort</Button>
                <Button onClick={() => onSort(createShuffleSort())}>
                    Shuffle
                </Button>
                <TextInput
                    value={filterString}
                    onChange={onFilter}
                    placeholder="Search"
                />
            </div>
            <KeyboardShortcuts onKey={onKey} />
            <div className="gallery-page__tiles">
                <LazyGridView
                    keyProvider={(g) => g._id}
                    items={visible}
                    size={{
                        item: new Vector(200, 250),
                        margin: new Vector(20, 20),
                        padding: new Vector(20, 20),
                    }}
                    initialScroll={initialScroll}
                    onScroll={onScroll}
                    api={api}
                >
                    {(g) => (
                        <div className="gallery-page__tile">
                            <HoverIcon
                                onClick={() =>
                                    history.push(`/galleries/${g._id}/edit`)
                                }
                                icon={faPencilAlt}
                            >
                                <GalleryTile
                                    gallery={g}
                                    selected={selected === g._id}
                                    onSelect={() => onSelect(g._id)}
                                    onOpen={() =>
                                        history.push(`/galleries/${g._id}/view`)
                                    }
                                />
                            </HoverIcon>
                        </div>
                    )}
                </LazyGridView>
            </div>
        </div>
    );
}
