import Axios from "axios";
import * as t from "io-ts";
import { Gallery, GalleryInterface, GallerySchema } from "../models/Gallery";
import { parse } from "../models/Models";

export class GalleryService {
    static async create(gallery: GalleryInterface): Promise<void> {
        return Axios.post("/api/galleries", parse(gallery, GallerySchema));
    }

    static async update(gallery: GalleryInterface): Promise<void> {
        return Axios.post("/api/galleries", parse(gallery, GallerySchema));
    }

    static async list(): Promise<Gallery[]> {
        const response = await Axios.get("/api/galleries");
        response.data.results.forEach(normalize);
        const data = parse(
            response.data,
            t.strict({
                results: t.array(GallerySchema)
            })
        );
        return data.results.map(Gallery.fromData);
    }
}

function normalize(g: Partial<GalleryInterface>) {
    if (g._id == null) g._id = "";
    if (g.album == null) g.album = "";
    if (g.created_at == null) g.created_at = "";
    if (g.deletedImages == null) g.deletedImages = [];
    if (g.images == null) g.images = [];
    if (g.name == null) g.name = "";
    if (g.order == null) g.order = 0;
    if (g.previewImage == null) g.previewImage = "";
    if (g.tags == null) g.tags = [];
    if (g.updated_at == null) g.updated_at = "";
    if (g.deleted == null) g.deleted = false;
}
