import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Video } from "../models/Video";
export function fileUrl(id: string) {
    return `/api/files/binary/${id}`;
}
export function ViewVideoPage({ videos }: { videos: Video[] }) {
    const match = useRouteMatch<{ id: string }>();
    const video = videos.find((v) => v._id === match.params.id);
    if (video == null) {
        return <div>No Video</div>;
    }
    return (
        <div>
            <video controls src={fileUrl(video.video)} />
        </div>
    );
}
