import * as t from "io-ts";

export const TextSchema = t.strict({
    _id: t.string,
    text: t.string,
    title: t.string,
    created_at: t.string,
    updated_at: t.string
});

export interface TextData extends t.TypeOf<typeof TextSchema> {}

export class Text implements TextData {
    constructor(
        readonly _id: string = "",
        readonly text: string = "",
        readonly title: string = "",
        readonly created_at: string = "",
        readonly updated_at: string = ""
    ) {}

    copy(values: Partial<TextData>): Text {
        return Object.assign(new Text(), this, values);
    }
}
