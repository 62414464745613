import React, { useEffect, useState } from "react";
import { Callback } from "../util/Callback";
import "./NumberInput.css";

export function NumberInput({
    value,
    onChange
}: {
    value: number;
    onChange: Callback<number>;
}) {
    const [stringValue, setStringValue] = useState(value.toString());

    useEffect(() => {
        setStringValue(value.toString());
    }, [value]);

    function onBlur() {
        const numberValue = parseFloat(stringValue);
        if (!isNaN(numberValue)) {
            onChange(numberValue);
        }
        setStringValue(value.toString());
    }
    return (
        <input
            type="number"
            className="number-input"
            value={stringValue}
            onChange={e => setStringValue(e.target.value)}
            onBlur={onBlur}
        />
    );
}

export function NumberControl({
    value,
    onChange,
    label
}: {
    value: number;
    onChange: Callback<number>;
    label: React.ReactNode;
}) {
    return (
        <div className="control">
            <label htmlFor="" className="label">
                {label}
            </label>
            <NumberInput value={value} onChange={onChange} />
        </div>
    );
}
