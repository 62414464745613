import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { Text } from "../models/Text";
import { TextsService } from "../services/TextsService";
import { Callback } from "../util/Callback";
import { TextForm } from "./TextForm";

export function AddTextPage({
    onChangeText,
}: {
    onChangeText: Callback<void>;
}) {
    const [text, setText] = useState(new Text());
    const history = useHistory();

    async function save() {
        await TextsService.create(text);
        history.push("/texts");
        onChangeText();
    }

    return (
        <div className="edit-text-page">
            <h1>Create Text</h1>
            <Button onClick={save}>Save</Button>
            <TextForm text={text} onChange={setText} />
        </div>
    );
}
