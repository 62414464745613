import * as t from "io-ts";

export const UploadSchema = t.strict({
    filename: t.string,
    mimetype: t.string,
    fileID: t.string,
    created_at: t.string,
    updated_at: t.string,
    _id: t.string,
});

export interface Upload extends t.TypeOf<typeof UploadSchema> {}
