export function limit(value: number, a: number, b: number) {
    if (value < a) {
        return a;
    }
    if (value > b) {
        return b;
    }
    return value;
}

export function limitF(a: number, b: number) {
    return (value: number) => {
        if (value < a) {
            return a;
        }
        if (value > b) {
            return b;
        }
        return value;
    };
}
