import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { FormElement } from "../common/FormElement";
import { Text } from "../models/Text";
import "./TextsPage.css";

export function TextsPage({ texts }: { texts: Text[] }) {
    const history = useHistory();
    return (
        <div className="texts-page">
            <FormElement>
                <Button onClick={() => history.push("/texts/create")}>
                    Create
                </Button>
            </FormElement>
            <FormElement>
                {texts.map((t) => (
                    <div className="texts-page__item" key={t._id}>
                        <a
                            href="/"
                            className="texts-page__link"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(`/texts/${t._id}/view`);
                            }}
                        >
                            {t.title || "Untitled"}
                        </a>
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="text-page__edit"
                            onClick={() => {
                                history.push(`/texts/${t._id}/edit`);
                            }}
                        />
                    </div>
                ))}
            </FormElement>
        </div>
    );
}
