import { fold } from "fp-ts/lib/Either";
import * as t from "io-ts";

export function parse<T extends {}, D>(data: T, type: t.Type<D>): D {
    return fold(
        e => {
            console.error(e);
            throw e;
        },
        (d: D) => d
    )(type.decode(data));
}
