import { FileSelector } from "../uploads/FileSelector";
import { FileUploadService } from "../uploads/FileUploadService";

export function AddImage({
    onAddImage,
}: {
    onAddImage: (images: string[]) => void;
}) {
    async function onFiles(files: File[]) {
        const result = [];
        for (let file of files) {
            const upload = await FileUploadService.upload(file);
            result.push(upload);
        }
        onAddImage(result.map((r) => r._id));
    }
    return (
        <div>
            <h3>Add Image</h3>
            <FileSelector onFiles={onFiles} />
        </div>
    );
}
