import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { KeyboardShortcuts, Keys } from "../common/KeyboardShortcuts";
import { GalleryImage } from "../gallery/GalleryImage";
import { Gallery } from "../models/Gallery";
import { useDimensions } from "../util/Dimesions";
import { limitF } from "../util/Numbers";
import { Layout, LayoutSelector } from "./LayoutSelector";
import "./PageDisplay.css";
import { PageNumber } from "./PageNumber";

export function PageDisplay({ gallery }: { gallery: Gallery }) {
    const [page, setPage] = useState(0);
    const [layout, setLayout] = useState(Layout.Fit);
    const [ref, dimensions] = useDimensions<HTMLDivElement>();
    const [scroll, setScroll] = useState<HTMLDivElement | null>(null);
    const scrollRef = useCallback((node) => {
        setScroll(node);
    }, []);

    useEffect(() => {
        setPage(0);
    }, [gallery._id]);

    const pageLimiter = limitF(0, gallery.images.length - 1);

    function nextPage() {
        toPage(page + 1);
    }
    function previousPage() {
        toPage(page - 1);
    }

    function toPage(number: number) {
        if (dimensions == null || scroll == null) {
            return;
        }
        scroll.scrollTo({
            left: pageLimiter(number) * dimensions.width,
        });
    }

    function onClick(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        const target = event.target as Element;
        const rect = target.getBoundingClientRect();
        const ratio = (event.clientX - rect.left) / (rect.right - rect.left);
        if (ratio < 1 / 3) {
            previousPage();
            event.preventDefault();
        } else if (ratio > 2 / 3) {
            nextPage();
            event.preventDefault();
        }
    }

    function updatePage() {
        if (scroll == null || dimensions == null) {
            return;
        }
        setPage(pageLimiter(Math.round(scroll.scrollLeft / dimensions.width)));
    }

    useLayoutEffect(() => updatePage());

    function onKey(key: Keys) {
        switch (key) {
            case Keys.ArrowLeft:
                previousPage();
                return true;
            case Keys.ArrowRight:
                nextPage();
                return true;
            case Keys.G:
                const enteredPage = parseInt(prompt("Go To Page?") ?? "", 10);
                if (!isNaN(enteredPage)) {
                    toPage(enteredPage - 1);
                }
                return true;
            default:
                return false;
        }
    }

    const [isLoaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => setLoaded(true), 1000);
    }, []);

    return (
        <div className="page-display" ref={ref}>
            <KeyboardShortcuts onKey={onKey} />
            <div className="page-display__layout-selector">
                <LayoutSelector layout={layout} onChange={setLayout} />
            </div>
            <div
                className={[
                    "page-display__image-container",
                    isLoaded ? "page-display__image-container--snap" : "",
                ].join(" ")}
                onClick={onClick}
                onScroll={updatePage}
                ref={scrollRef}
            >
                {gallery.images.map((i, index) => (
                    <div
                        className={[
                            "page-display__image",
                            `page-display__image--${layout}`,
                        ].join(" ")}
                        style={{
                            width: dimensions?.width ?? 0,
                            height: dimensions?.height ?? 0,
                        }}
                        key={i}
                    >
                        {Math.abs(index - page) < 30 && (
                            <GalleryImage key={i} id={i} />
                        )}
                    </div>
                ))}
            </div>
            <div className="page-display__page-number">
                <PageNumber current={page + 1} total={gallery.images.length} />
            </div>
        </div>
    );
}
