import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { FormElement } from "../common/FormElement";
import { Video } from "../models/Video";
import "./VideoPage.css";
export function VideosPage({ videos }: { videos: Video[] }) {
    const history = useHistory();
    return (
        <div className="video-page">
            <FormElement>
                <Button onClick={() => history.push("/videos/create")}>
                    Create
                </Button>
            </FormElement>
            <FormElement>
                <div className="video-list">
                    {videos.map((v) => (
                        <Link
                            to={`/videos/${v._id}/view`}
                            key={v._id}
                            className="video-item"
                        >
                            {v.name}
                        </Link>
                    ))}
                </div>
            </FormElement>
        </div>
    );
}
