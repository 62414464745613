import * as t from "io-ts";

export const GallerySchema = t.strict({
    album: t.string,
    name: t.string,
    order: t.number,
    previewImage: t.string,
    tags: t.array(t.string),
    images: t.array(t.string),
    deletedImages: t.array(t.string),
    created_at: t.string,
    updated_at: t.string,
    deleted: t.boolean,
    _id: t.string,
});

export interface GalleryInterface extends t.TypeOf<typeof GallerySchema> {}

export class Gallery implements GalleryInterface {
    static fromData(data: GalleryInterface): Gallery {
        return Object.assign(new Gallery(), data);
    }

    constructor(
        readonly _id: string = "",
        readonly album: string = "",
        readonly name: string = "",
        readonly order: number = 0,
        readonly previewImage: string = "",
        readonly tags: string[] = [],
        readonly images: string[] = [],
        readonly deletedImages: string[] = [],
        readonly created_at: string = "",
        readonly updated_at: string = "",
        readonly deleted: boolean = false
    ) {}

    copy(values: Partial<GalleryInterface>): Gallery {
        return Object.assign(new Gallery(), this, values);
    }

    deleteImage(image: string) {
        const newImages = this.images.filter((i) => i !== image);
        const deletedImages = new Set(this.deletedImages);
        deletedImages.add(image);
        return this.copy({
            images: newImages,
            deletedImages: Array.from(deletedImages),
        });
    }

    restoreImage(image: string) {
        const newDeleted = this.deletedImages.filter((i) => i !== image);
        const newImages = new Set(this.images);
        newImages.add(image);
        return this.copy({
            images: Array.from(newImages),
            deletedImages: newDeleted,
        });
    }

    getPreviewImage() {
        return this.previewImage || this.images[0];
    }
}
