import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "../common/Button";
import { Text } from "../models/Text";
import { TextsService } from "../services/TextsService";
import { Callback } from "../util/Callback";
import { TextForm } from "./TextForm";
export function EditTextPage({
    texts,
    onChangeText,
}: {
    texts: Text[];
    onChangeText: Callback<void>;
}) {
    const match = useRouteMatch<{ id: string }>();
    const [text, setEdit] = useState<Text | null>(null);
    useEffect(() => {
        setEdit(texts.find((g) => g._id === match.params.id) ?? null);
    }, [match.params.id, texts]);
    const history = useHistory();

    if (text == null) {
        return <div>No Text</div>;
    }

    async function save() {
        if (text == null) {
            return;
        }
        await TextsService.update(text);
        onChangeText();
        history.push("/texts");
    }

    return (
        <div className="edit-text-page">
            <h1>Edit</h1>
            <div>
                <Button onClick={save}>Save</Button>
            </div>
            <TextForm text={text} onChange={setEdit} />
        </div>
    );
}
