import Axios from "axios";
import { parse } from "../models/Models";
import { Upload, UploadSchema } from "./Upload";

export class FileUploadService {
    static async upload(
        file: File,
        onUploadProgress?: (e: ProgressEvent) => void
    ): Promise<Upload> {
        const data = new FormData();
        data.append("file", file);
        const response = await Axios.post("/api/files", data, {
            onUploadProgress: onUploadProgress,
        });
        return parse(response.data, UploadSchema);
    }
}
