import Axios from "axios";

export class AuthService {
    static async isSignedIn(): Promise<boolean> {
        try {
            await Axios.get("/api/auth");
            return true;
        } catch (e) {
            if (e.response.status === 401) {
                return false;
            }
            throw e;
        }
    }

    static async login(password: string): Promise<void> {
        await Axios.post("/api/login", { password });
    }
}
