import { Gallery } from "../models/Gallery";

export class Galleries {
    static nextOrder(galleries: Gallery[]) {
        if (galleries.length === 0) {
            return 1;
        }
        return Math.max(...galleries.map(g => g.order)) + 1;
    }
}
