import React from "react";
import { FormElement } from "../common/FormElement";
import { TextArea } from "../common/TextArea";
import { TextControl } from "../common/TextInput";
import { Text } from "../models/Text";
import { Callback } from "../util/Callback";

export function TextForm({
    text,
    onChange
}: {
    text: Text;
    onChange: Callback<Text>;
}) {
    return (
        <div className="text-form">
            <FormElement>
                <TextControl
                    value={text.title}
                    onChange={t =>
                        onChange(
                            text.copy({
                                title: t
                            })
                        )
                    }
                    label="Title"
                />
            </FormElement>
            <FormElement>
                <TextArea
                    value={text.text}
                    onChange={t =>
                        onChange(
                            text.copy({
                                text: t
                            })
                        )
                    }
                />
            </FormElement>
        </div>
    );
}
