import React, {
    MutableRefObject,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { Callback } from "../util/Callback";
import { useDebounced } from "../util/Debounce";
import { checkNotNull } from "../util/Nullable";
import "./LazyGridView.css";
import { Vector } from "./Vector";

export interface Api {
    computeVisible(): Vector;
    scrollTo(index: number): void;
}

interface Size {
    item: Vector;
    margin: Vector;
    padding: Vector;
}
const EXTRA_ROWS = 4;

export function LazyGridView<T>({
    items,
    keyProvider,
    children,
    size: { item, margin, padding },
    initialScroll,
    onScroll,
    api,
}: {
    items: T[];
    keyProvider: (t: T) => string;
    children: (t: T, index: number) => ReactNode;
    size: Size;
    initialScroll?: RefObject<number>;
    onScroll?: Callback<number>;
    api?: MutableRefObject<Api | undefined>;
}) {
    const [scroll, setScroll] = useState(0);
    const [screen, setScreen] = useState(new Vector(1000, 1000));
    const scrollContianer = useRef<HTMLDivElement>(null);

    const total = item.add(margin);
    const gridTemplate = `${item.x}px repeat(auto-fill, minmax(${total.x}px, 1fr))`;

    useLayoutEffect(() => {
        if (scrollContianer.current) {
            scrollContianer.current.scrollTo({
                top: initialScroll?.current || 0,
            });
        }
    }, [initialScroll]);

    function visible() {
        const count = screen.add(margin).sub(padding).sub(padding).div(total);
        if (count.x < 2) {
            return new Vector(2, Math.ceil(count.y));
        }
        return new Vector(Math.floor(count.x), Math.ceil(count.y));
    }

    function top() {
        return Math.floor((scroll + margin.y - padding.y) / total.y);
    }

    const currentVisible = visible();
    const rowSize = currentVisible.x;
    const totalRows = Math.ceil(items.length / rowSize);
    const firstRow = Math.max(top() - EXTRA_ROWS, 0);
    const renderedRows = currentVisible.y + EXTRA_ROWS + EXTRA_ROWS;
    const lastRow = Math.min(firstRow + renderedRows, totalRows);

    const startIndex = firstRow * rowSize;
    const endIndex = lastRow * rowSize;
    const slice = items.slice(startIndex, endIndex);
    const paddingBefore = firstRow * total.y;
    const paddingAfter = (totalRows - lastRow) * total.y;

    const update = useDebounced(
        useCallback(() => {
            const div = scrollContianer.current;
            if (!div) {
                throw new Error();
            }
            setScreen(new Vector(div.clientWidth, div.clientHeight));
            onScroll && onScroll(div.scrollTop);
            setScroll(div.scrollTop);
        }, [onScroll, setScroll])
    );

    useEffect(() => {
        update();
        window.addEventListener("resize", update);
        return () => window.removeEventListener("resize", update);
    }, [update]);

    useEffect(() => {
        if (api == null) {
            return;
        }
        api.current = {
            computeVisible() {
                return visible();
            },
            scrollTo(index: number) {
                const scrollTop = checkNotNull(
                    scrollContianer.current
                ).scrollTop;
                const clientHeight = checkNotNull(
                    scrollContianer.current
                ).clientHeight;
                const currentVisible = visible();
                const row = Math.floor(index / currentVisible.x);
                const itemTop = row * total.y;
                const itemBottom = itemTop + total.y;
                if (itemBottom > scrollTop + clientHeight) {
                    checkNotNull(scrollContianer.current).scrollTo({
                        top: itemBottom - clientHeight,
                        behavior: "smooth",
                    });
                } else if (itemTop < scrollTop) {
                    checkNotNull(scrollContianer.current).scrollTo({
                        top: itemTop,
                        behavior: "smooth",
                    });
                }
            },
        };
    });

    return (
        <div
            className="lazy-grid-view"
            ref={scrollContianer}
            onScroll={update}
            style={{
                padding: `${padding.y}px ${padding.x}px`,
            }}
        >
            <div style={{ height: paddingBefore }} />
            <div
                className="lazy-grid-view__items"
                style={{
                    gridTemplateColumns: gridTemplate,
                }}
            >
                {slice.map((i, index) => (
                    <div
                        className="lazy-grid-view__item"
                        key={keyProvider(i)}
                        style={{ height: total.y }}
                    >
                        {children(i, index + startIndex)}
                    </div>
                ))}
            </div>
            <div style={{ height: paddingAfter }} />
        </div>
    );
}
