import React from "react";
import "./ProgressBar.css";

export function ProgressBar({
    current,
    total
}: {
    current: number;
    total: number;
}) {
    return (
        <div className="progress-bar">
            <div
                className="progress-bar__bar"
                style={{ width: `${(current / total) * 100}%` }}
            ></div>
        </div>
    );
}
