import React from "react";
import { Gallery } from "../models/Gallery";
import { Callback } from "../util/Callback";
import { GalleryImage } from "./GalleryImage";
import "./GalleryTile.css";

export function GalleryTile({
    gallery,
    selected,
    onSelect,
    onOpen,
}: {
    gallery: Gallery;
    selected: boolean;
    onSelect: Callback<void>;
    onOpen: Callback<void>;
}) {
    return (
        <div
            className={[
                "gallery-tile",
                selected ? "gallery-tile--selected" : "",
            ].join(" ")}
            onClick={(e) => {
                e.preventDefault();
                onSelect();
            }}
            onDoubleClick={(e) => {
                e.preventDefault();
                onOpen();
            }}
        >
            <div className="gallery-tile__image">
                <GalleryImage id={gallery.getPreviewImage()} thumbnail />
            </div>
            {gallery.name}
        </div>
    );
}
