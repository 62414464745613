import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { KeyboardShortcuts, Keys } from "../common/KeyboardShortcuts";
import { Gallery } from "../models/Gallery";
import { PageDisplay } from "./PageDisplay";
import "./ViewGalleryPage.css";

export function ViewGalleryPage({ galleries }: { galleries: Gallery[] }) {
    const match = useRouteMatch<{ id: string }>();
    const gallery = galleries.find((g) => g._id === match.params.id) ?? null;
    const history = useHistory();
    if (!gallery) {
        return <div>Select a gallery to read</div>;
    }
    return (
        <div className="view-gallery-page">
            <KeyboardShortcuts
                onKey={(k) => {
                    if (k === Keys.Escape) {
                        history.push("/");
                        return true;
                    } else {
                        return false;
                    }
                }}
            />
            <PageDisplay gallery={gallery} />
        </div>
    );
}
