import React from "react";
import { Callback } from "../util/Callback";
import "./TextInput.css";

export function TextInput({
    value,
    onChange,
    placeholder
}: {
    value: string;
    onChange: Callback<string>;
    placeholder?: string;
}) {
    return (
        <input
            type="text"
            className="text-input"
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
        />
    );
}

export function TextControl({
    value,
    onChange,
    label
}: {
    value: string;
    onChange: Callback<string>;
    label: React.ReactNode;
}) {
    return (
        <div className="control">
            <label htmlFor="" className="label">
                {label}
            </label>
            <TextInput value={value} onChange={onChange} />
        </div>
    );
}
