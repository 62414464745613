import React, { useEffect, useRef } from "react";

export enum Keys {
    Escape = "Escape",
    ArrowDown = "ArrowDown",
    ArrowRight = "ArrowRight",
    ArrowLeft = "ArrowLeft",
    ArrowUp = "ArrowUp",
    Enter = "Enter",
    G = "g",
}

export function KeyboardShortcuts({
    onKey,
}: {
    onKey: (key: Keys) => boolean;
}) {
    const ref = useRef(onKey);

    useEffect(() => {
        ref.current = onKey;
    });

    useEffect(() => {
        function onKeyUp(event: KeyboardEvent) {
            if (ref.current(event.key as Keys)) {
                event.preventDefault();
            }
        }

        window.addEventListener("keydown", onKeyUp);
        return () => window.removeEventListener("keydown", onKeyUp);
    }, []);
    return <></>;
}
