import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Callback } from "../util/Callback";
import "./HoverIcon.css";

export enum Position {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
}

export enum Style {
    Primary,
    Danger,
}

export function HoverIcon({
    children,
    icon,
    onClick,
    muted,
    position,
    style,
}: {
    children: ReactNode;
    icon: IconProp;
    onClick: Callback<void>;
    muted?: boolean;
    position?: Position;
    style?: Style;
}) {
    function getPosition(): string {
        switch (position) {
            case Position.TopLeft:
                return "hover-icon__button--top-left";
            case Position.BottomLeft:
                return "hover-icon__button--bottom-left";
            case Position.BottomRight:
                return "hover-icon__button--bottom-left";
            default:
                return "hover-icon__button--top-right";
        }
    }
    function getStyle(): string {
        switch (style) {
            case Style.Danger:
                return "hover-icon__button--danger";
            default:
                return "hover-icon__button--primary";
        }
    }
    return (
        <div className="hover-icon">
            <button
                className={[
                    "hover-icon__button",
                    muted ? "hover-icon__button--muted" : "",
                    getPosition(),
                    getStyle(),
                ].join(" ")}
                onClick={() => onClick()}
            >
                <FontAwesomeIcon icon={icon} size="2x" />
            </button>
            {children}
        </div>
    );
}
