import React from "react";
import "./FormElement.css";

export function FormElement({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={["form-element", className].filter((i) => i).join(" ")}>
            {children}
        </div>
    );
}
