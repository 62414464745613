import { SyntheticEvent, useState } from "react";
import { AuthService } from "./services/AuthService";
import { Callback } from "./util/Callback";

export function LoginPage({ onLogin }: { onLogin: Callback<void> }) {
    const [password, setPassword] = useState("");
    async function login(e: SyntheticEvent<HTMLButtonElement>) {
        e.preventDefault();

        try {
            await AuthService.login(password);
            onLogin();
        } catch (e) {
            console.error(e);
            alert("Failed");
        }
    }

    return (
        <form>
            <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" onClick={login}>
                Submit
            </button>
        </form>
    );
}
