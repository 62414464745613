import React, { useEffect, useRef } from "react";
import { ImageRoutes } from "../services/ImageRoutes";
import "./GalleryImage.css";

export function GalleryImage({
    id,
    preload,
    thumbnail,
}: {
    id: string;
    preload?: boolean;
    thumbnail?: boolean;
}) {
    const ref = useRef<HTMLImageElement>(null);
    useEffect(() => {
        if (preload && ref.current) {
            ref.current.decode();
        }
    }, [preload]);
    if (!id) {
        return null;
    }
    return (
        <img
            ref={ref}
            src={thumbnail ? ImageRoutes.thumbnail(id) : ImageRoutes.image(id)}
            alt=""
            className="gallery-image"
            style={preload ? { display: "none" } : {}}
        />
    );
}
