import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { FormElement } from "../common/FormElement";
import { ProgressBar } from "../common/ProgressBar";
import { TextControl } from "../common/TextInput";
import { Video } from "../models/Video";
import { VideoService } from "../services/VideoService";
import { FileUploadService } from "../uploads/FileUploadService";
import { Callback } from "../util/Callback";
export function CreateVideoPage({
    onChangeVideo,
}: {
    onChangeVideo: Callback<void>;
}) {
    const [video, setVideo] = useState(new Video());
    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState<ProgressEvent | null>(null);
    const history = useHistory();
    async function create() {
        if (file == null) {
            return;
        }
        const upload = await FileUploadService.upload(file, setProgress);
        const v = video.copy({ video: upload._id });
        await VideoService.create(v);
        onChangeVideo();
        history.push("/videos");
    }
    return (
        <div>
            <FormElement>
                <TextControl
                    value={video.name}
                    onChange={(t) =>
                        setVideo(
                            video.copy({
                                name: t,
                            })
                        )
                    }
                    label="Name"
                />
                <input
                    type="file"
                    onChange={(e) => {
                        setFile(e.target.files?.item(0) ?? null);
                    }}
                    accept=".mp4"
                />
            </FormElement>
            {progress && (
                <ProgressBar current={progress.loaded} total={progress.total} />
            )}
            <Button onClick={create}>Create</Button>
        </div>
    );
}
