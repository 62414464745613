import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowsAltH,
    faArrowsAltV,
    faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Callback } from "../util/Callback";
import "./LayoutSelector.css";

export enum Layout {
    Fit = "fit",
    Real = "real",
    FitWidth = "fit-width",
}

export function LayoutSelector({
    layout,
    onChange,
}: {
    layout: Layout;
    onChange: Callback<Layout>;
}) {
    function render(l: Layout, icon: IconProp) {
        return (
            <FontAwesomeIcon
                onClick={() => onChange(l)}
                icon={icon}
                size="2x"
                className={[
                    "layout-selector__icon",
                    l === layout ? "layout-selector__icon--active" : "",
                ].join(" ")}
            />
        );
    }

    return (
        <div className="layout-selector">
            {render(Layout.Fit, faArrowsAltV)}
            {render(Layout.FitWidth, faArrowsAltH)}
            {render(Layout.Real, faExpandArrowsAlt)}
        </div>
    );
}
