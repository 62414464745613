import marked from "marked";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Text } from "../models/Text";
import "./ReadTextPage.css";

export function ReadTextPage({ texts }: { texts: Text[] }) {
    const match = useRouteMatch<{ id: string }>();
    const text = texts.find((t) => t._id === match.params.id);

    if (text == null) {
        return <div>No text</div>;
    }

    const getLinks = () => {
        const regex = /#+ (.*)/g;
        const results = [];
        for (
            let match = regex.exec(text.text);
            match != null;
            match = regex.exec(text.text)
        ) {
            const title = match[1];
            const anchor = title
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^a-z0-9-]/gi, "");
            results.push({ link: `#${anchor}`, title });
        }
        return results;
    };

    const getHTML = () => {
        return {
            __html: marked(text.text),
        };
    };

    return (
        <div className="read-text-page">
            <div className="read-text-page__content">
                <h1>{text.title}</h1>
                {getLinks().map((l) => (
                    <div key={l.link}>
                        <a href={l.link}>{l.title}</a>
                    </div>
                ))}
                <div dangerouslySetInnerHTML={getHTML()}></div>
            </div>
        </div>
    );
}
