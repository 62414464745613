import React, { useEffect, useState } from "react";
import "./ImagePreview.css";

export function ImagePreview({ file }: { file: File }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        const imageUrl = URL.createObjectURL(file);
        setUrl(imageUrl);
        return () => URL.revokeObjectURL(imageUrl);
    }, [file]);

    return (
        <div className="image-preview">
            {url && (
                <img src={url} className="image-preview__image" alt="img" />
            )}
        </div>
    );
}
