import React from "react";
import { Callback } from "../util/Callback";
import "./TextArea.css";

export function TextArea({
    value,
    onChange,
    placeholder
}: {
    value: string;
    onChange: Callback<string>;
    placeholder?: string;
}) {
    return (
        <textarea
            className="text-area"
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
        />
    );
}
