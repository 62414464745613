export class Vector {
    constructor(readonly x: number, readonly y: number) {}

    add(v: Vector) {
        return new Vector(this.x + v.x, this.y + v.y);
    }
    sub(v: Vector) {
        return new Vector(this.x - v.x, this.y - v.y);
    }
    div(v: Vector) {
        return new Vector(this.x / v.x, this.y / v.y);
    }
    ceil() {
        return new Vector(Math.ceil(this.x), Math.ceil(this.y));
    }
}
