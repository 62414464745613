import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Vector } from "./common/Vector";
import { Content } from "./Content";
import { LoginPage } from "./LoginPage";
import { Navigation } from "./Navigation";
import { AuthService } from "./services/AuthService";

export function App() {
    const [authed, setAuthed] = useState<boolean | null>(null);
    const [size, setSize] = useState<Vector | null>(null);

    useEffect(() => {
        function updateSize() {
            setSize(
                new Vector(
                    document.documentElement.clientWidth,
                    document.documentElement.clientHeight
                )
            );
        }
        let frame = requestAnimationFrame(updateSize);
        function update() {
            cancelAnimationFrame(frame);
            frame = requestAnimationFrame(updateSize);
        }
        window.addEventListener("resize", update);
        return () => window.removeEventListener("resize", update);
    }, []);

    function getStyle() {
        if (size == null) {
            return {};
        }
        return { width: size.x, height: size.y };
    }

    useEffect(() => {
        AuthService.isSignedIn().then(setAuthed);
    }, []);

    if (authed == null) {
        return <div>Loading</div>;
    }
    if (authed === false) {
        return <LoginPage onLogin={() => setAuthed(true)} />;
    }

    return (
        <Router>
            <div className="app" style={getStyle()}>
                <div className="app__nav">
                    <Navigation lastGallery="" />
                </div>
                <div className="app__content">
                    <Content />
                </div>
            </div>
        </Router>
    );
}
