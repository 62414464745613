import Axios from "axios";
import * as t from "io-ts";
import { parse } from "../models/Models";
import { Video, VideoInterface, VideoSchema } from "../models/Video";

export class VideoService {
    static async create(gallery: VideoInterface): Promise<void> {
        return Axios.post("/api/videos", parse(gallery, VideoSchema));
    }

    static async update(gallery: VideoInterface): Promise<void> {
        return Axios.post("/api/videos", parse(gallery, VideoSchema));
    }

    static async list(): Promise<Video[]> {
        const response = await Axios.get("/api/videos");
        const data = parse(
            response.data,
            t.strict({
                results: t.array(VideoSchema),
            })
        );
        return data.results.map(Video.fromData);
    }
}
