import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button } from "../common/Button";
import { FormElement } from "../common/FormElement";
import { HoverIcon } from "../common/HoverIcon";
import { ProgressBar } from "../common/ProgressBar";
import { TextControl } from "../common/TextInput";
import { Gallery } from "../models/Gallery";
import { GalleryService } from "../services/GalleryService";
import { Callback } from "../util/Callback";
import { FileSelector } from "./FileSelector";
import { FileUploadService } from "./FileUploadService";
import { ImagePreview } from "./ImagePreview";
import { Upload } from "./Upload";
import "./UploadPage.css";

export function UploadPage({
    onCreated,
    nextOrder,
}: {
    onCreated: Callback<void>;
    nextOrder: number;
}) {
    const [files, setFiles] = useState<File[]>([]);
    const [gallery, setGallery] = useState(new Gallery());
    const [progress, setProgress] = useState<number | null>(null);
    async function create() {
        let progress = 0;
        const images: Upload[] = [];
        for (const file of files) {
            images.push(await FileUploadService.upload(file));
            setProgress(++progress);
        }
        await GalleryService.create(
            gallery.copy({
                images: images.map((i) => i._id),
                order: nextOrder,
            })
        );
        setProgress(null);
        setFiles([]);
        setGallery(new Gallery());
        onCreated();
    }

    return (
        <div className="upload-page">
            <FileSelector onFiles={(f) => setFiles([...files, ...f])} />
            <FormElement>
                <TextControl
                    label="Name"
                    value={gallery.name}
                    onChange={(n) => setGallery(gallery.copy({ name: n }))}
                />
            </FormElement>
            <FormElement>
                <Button onClick={create} disabled={files.length === 0}>
                    Upload
                </Button>
            </FormElement>
            {progress && (
                <FormElement>
                    <ProgressBar current={progress} total={files.length + 1} />
                </FormElement>
            )}
            <h3>{files.length} Images</h3>
            <div className="upload-page__images">
                {files.map((file, i) => (
                    <div className="upload-page__preview" key={file.name + i}>
                        <HoverIcon
                            icon={faTimes}
                            onClick={() =>
                                setFiles(files.filter((f) => f !== file))
                            }
                        >
                            <ImagePreview file={file} />
                        </HoverIcon>
                    </div>
                ))}
            </div>
        </div>
    );
}
