import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "../common/Button";
import { FormElement } from "../common/FormElement";
import { NumberControl } from "../common/NumberInput";
import { TextControl } from "../common/TextInput";
import { Gallery } from "../models/Gallery";
import { GalleryService } from "../services/GalleryService";
import { Callback } from "../util/Callback";
import { AddImage } from "./AddImage";
import "./EditGalleryPage.css";
import { ImageSort } from "./ImageSort";

export function EditGalleryPage({
    galleries,
    onChangeGallery,
}: {
    galleries: Gallery[];
    onChangeGallery: Callback<void>;
}) {
    const match = useRouteMatch<{ id: string }>();
    const [gallery, setEdit] = useState<Gallery | null>(null);
    const history = useHistory();

    useEffect(() => {
        setEdit(galleries.find((g) => g._id === match.params.id) ?? null);
    }, [match.params.id, galleries]);

    if (gallery == null) {
        return <div>No Gallery to Edit</div>;
    }

    async function save() {
        if (gallery != null) {
            await GalleryService.update(gallery);
            onChangeGallery();
            history.push("/");
        }
    }

    async function toggleDelete() {
        if (gallery != null) {
            if (
                !gallery.deleted &&
                !window.confirm("Are you sure you want to delete this gallery?")
            ) {
                return;
            }
            const updated = gallery.copy({ deleted: !gallery.deleted });
            await GalleryService.update(updated);
            onChangeGallery();
            history.push("/");
        }
    }

    return (
        <div className="edit-gallery-page">
            <div className="edit-gallery-page__header">
                <FormElement className="edit-gallery-page__name">
                    <TextControl
                        label="Name"
                        value={gallery.name}
                        onChange={(n) => setEdit(gallery.copy({ name: n }))}
                    />
                </FormElement>
                <FormElement className="edit-gallery-page__order">
                    <NumberControl
                        label="Order"
                        value={gallery.order}
                        onChange={(n) => setEdit(gallery.copy({ order: n }))}
                    />
                </FormElement>
                <div className="edit-gallery-page__actions">
                    <Button onClick={save}>Save</Button>
                    <Button onClick={toggleDelete}>
                        {gallery.deleted ? "Undelete" : "Delete"}
                    </Button>
                </div>
            </div>
            <h3>Images</h3>
            <ImageSort
                images={gallery.images}
                onSort={(i) => setEdit(gallery.copy({ images: i }))}
                onToggleDelete={(i) => setEdit(gallery.deleteImage(i))}
                previewImage={gallery.getPreviewImage()}
                onSetPreviewImage={(i) =>
                    setEdit(
                        gallery.copy({
                            previewImage: i,
                        })
                    )
                }
            />
            <AddImage
                onAddImage={(newImages) =>
                    setEdit(
                        gallery.copy({
                            images: [...gallery.images, ...newImages],
                        })
                    )
                }
            />
            <h3>Deleted Images</h3>
            <ImageSort
                images={gallery.deletedImages}
                onSort={(i) => setEdit(gallery.copy({ images: i }))}
                onToggleDelete={(i) => setEdit(gallery.restoreImage(i))}
                previewImage={gallery.getPreviewImage()}
                onSetPreviewImage={(i) =>
                    setEdit(
                        gallery.copy({
                            previewImage: i,
                        })
                    )
                }
            />
        </div>
    );
}
