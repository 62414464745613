import { useCallback, useRef } from "react";

export function useDebounced(f: () => void): () => void {
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const lastFunction = useRef(f);
    lastFunction.current = f;
    return useCallback(() => {
        if (timeout.current == null) {
            timeout.current = setTimeout(() => {
                timeout.current = null;
                lastFunction.current();
            }, 500);
        }
    }, []);
}
