import React from "react";
import "./Button.css";
export function Button({
    children,
    onClick,
    disabled
}: {
    children: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
}) {
    return (
        <button
            className={`button ${disabled ? "button--disabled" : ""}`}
            onClick={() => !disabled && onClick()}
        >
            {children}
        </button>
    );
}
