import Axios from "axios";
import * as t from "io-ts";
import { parse } from "../models/Models";
import { Text, TextData, TextSchema } from "../models/Text";

function normalize(t: Partial<TextData>) {
    if (t._id == null) t._id = "";
    if (t.title == null) t.title = "";
    if (t.text == null) t.text = "";
    if (t.created_at == null) t.created_at = "";
    if (t.updated_at == null) t.updated_at = "";
}

export class TextsService {
    static async list(): Promise<Text[]> {
        const response = await Axios.get("/api/texts");
        const data = response.data;
        data.results.forEach(normalize);
        return parse(
            data,
            t.strict({
                results: t.array(TextSchema)
            })
        ).results.map(t => new Text().copy(t));
    }

    static async create(text: Text): Promise<void> {
        await Axios.post("/api/texts", parse(text, TextSchema));
    }

    static async update(text: Text): Promise<void> {
        await Axios.post("/api/texts", parse(text, TextSchema));
    }
}
