import React, { useRef } from "react";
import { Callback } from "../util/Callback";
import "./FileSelector.css";

export function FileSelector({ onFiles }: { onFiles: Callback<File[]> }) {
    const input = useRef<HTMLInputElement | null>(null);

    function onChange() {
        const fileInput = input.current;
        if (fileInput != null && fileInput.files != null) {
            onFiles(Array.from(fileInput.files));
        }
    }

    return (
        <div className="file-selector">
            <input
                type="file"
                className="file-selector__input"
                onChange={onChange}
                ref={input}
                multiple
                accept="image/*"
            />
            Select Files
        </div>
    );
}
