import React from "react";
import "./PageNumber.css";

export function PageNumber({
    current,
    total
}: {
    current: number;
    total: number;
}) {
    return (
        <div className="page-number">
            {current}/{total}
        </div>
    );
}
