import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./Content.css";
import { EditGalleryPage } from "./edit-gallery/EditGalleryPage";
import { GalleryPage } from "./gallery/GalleryPage";
import { DEFAULT_SORT, Sort } from "./gallery/Sort";
import { Gallery } from "./models/Gallery";
import { Text } from "./models/Text";
import { Video } from "./models/Video";
import { ReadTextPage } from "./read-text/ReadTextPage";
import { Galleries } from "./services/Galleries";
import { GalleryService } from "./services/GalleryService";
import { TextsService } from "./services/TextsService";
import { VideoService } from "./services/VideoService";
import { AddTextPage } from "./texts/AddTextPage";
import { EditTextPage } from "./texts/EditTextPage";
import { TextsPage } from "./texts/TextsPage";
import { UploadPage } from "./uploads/UploadPage";
import { CreateVideoPage } from "./videos/CreateVideoPage";
import { VideosPage } from "./videos/VideosPage";
import { ViewVideoPage } from "./videos/ViewVideoPage";
import { ViewGalleryPage } from "./view-gallery/ViewGalleryPage";

export function Content() {
    const [galleries, setGalleries] = useState<Gallery[]>([]);
    const [selected, setSelected] = useState("");
    const scrollPosition = useRef(0);
    const [sort, setSort] = useState<Sort>(() => DEFAULT_SORT);
    const [filter, setFilter] = useState("");
    const [texts, setTexts] = useState<Text[]>([]);
    const [videos, setVideos] = useState<Video[]>([]);

    function loadGalleries() {
        GalleryService.list().then(setGalleries);
    }

    useEffect(loadGalleries, []);

    const onScroll = useCallback((s) => {
        scrollPosition.current = s;
    }, []);

    function loadTexts() {
        TextsService.list().then(setTexts);
    }

    useEffect(loadTexts, []);

    function loadVideos() {
        VideoService.list().then(setVideos);
    }

    useEffect(loadVideos, []);

    return (
        <div className="content">
            <Switch>
                <Route path="/galleries/create">
                    <UploadPage
                        onCreated={loadGalleries}
                        nextOrder={Galleries.nextOrder(galleries)}
                    />
                </Route>
                <Route path="/galleries/:id/edit">
                    <EditGalleryPage
                        galleries={galleries}
                        onChangeGallery={loadGalleries}
                    />
                </Route>
                <Route path="/galleries/:id/view">
                    <ViewGalleryPage galleries={galleries} />
                </Route>
                <Route path="/texts/create">
                    <AddTextPage onChangeText={loadTexts} />
                </Route>
                <Route path="/texts/:id/edit">
                    <EditTextPage texts={texts} onChangeText={loadTexts} />
                </Route>
                <Route path="/texts/:id/view">
                    <ReadTextPage texts={texts} />
                </Route>
                <Route path="/texts">
                    <TextsPage texts={texts} />
                </Route>
                <Route path="/videos/create">
                    <CreateVideoPage onChangeVideo={loadVideos} />
                </Route>
                <Route path="/videos/:id/view">
                    <ViewVideoPage videos={videos} />
                </Route>
                <Route path="/videos">
                    <VideosPage videos={videos} />
                </Route>
                <Route path="/">
                    <GalleryPage
                        galleries={galleries}
                        initialScroll={scrollPosition}
                        onScroll={onScroll}
                        selected={selected}
                        onSelect={setSelected}
                        sort={sort}
                        onSort={(s) => setSort(() => s)}
                        filterString={filter}
                        onFilter={setFilter}
                    />
                </Route>
            </Switch>
        </div>
    );
}
