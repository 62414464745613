import { faFlag, faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { HoverIcon, Position, Style } from "../common/HoverIcon";
import { LazyGridView } from "../common/LazyGridView";
import { NumberInput } from "../common/NumberInput";
import { Vector } from "../common/Vector";
import { GalleryImage } from "../gallery/GalleryImage";
import { Callback } from "../util/Callback";
import { limit } from "../util/Numbers";
import "./ImageSort.css";

export function ImageSort({
    images,
    onSort,
    previewImage,
    onToggleDelete,
    onSetPreviewImage,
}: {
    images: string[];
    onToggleDelete: (i: string) => void;
    onSort: Callback<string[]>;
    previewImage: string;
    onSetPreviewImage: Callback<string>;
}) {
    function updateOrder(from: number, to: number) {
        const realDestination = limit(to, 0, images.length - 1);
        const updated = images.slice();
        updated.splice(from, 1);
        updated.splice(realDestination, 0, images[from]);
        onSort(updated);
    }

    return (
        <div className="image-sort">
            <LazyGridView
                items={images}
                keyProvider={(i) => i}
                children={(image, index) => (
                    <div className="image-sort__tile" key={image}>
                        <HoverIcon
                            icon={faFlag}
                            onClick={() => onSetPreviewImage(image)}
                            muted={previewImage !== image}
                            position={Position.TopLeft}
                        >
                            <HoverIcon
                                icon={faTrash}
                                onClick={() => onToggleDelete(image)}
                                style={Style.Danger}
                            >
                                <div className="image-sort__image">
                                    <GalleryImage id={image} thumbnail />
                                </div>
                            </HoverIcon>
                        </HoverIcon>
                        <div className="image-sort__order">
                            <NumberInput
                                value={index + 1}
                                onChange={(next) =>
                                    updateOrder(index, next - 1)
                                }
                            />
                        </div>
                    </div>
                )}
                size={{
                    item: new Vector(200, 340),
                    margin: new Vector(40, 40),
                    padding: new Vector(0, 0),
                }}
            />
        </div>
    );
}
