import { Ref, useCallback, useLayoutEffect, useState } from "react";

export function useDimensions<T extends Element>(): [Ref<T>, DOMRect | null] {
    const [dimensions, setDimensions] = useState<DOMRect | null>(null);
    const [node, setNode] = useState<T | null>(null);

    const ref = useCallback((node: T) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(node.getBoundingClientRect())
                );
            measure();
            window.addEventListener("resize", measure);

            return () => {
                window.removeEventListener("resize", measure);
            };
        }
    }, [node]);

    return [ref, dimensions];
}
