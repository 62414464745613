import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faBook,
    faImages,
    faUpload,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";

function NavButton({ page, icon }: { page: string; icon: IconProp }) {
    return (
        <NavLink
            className="navigation__button"
            activeClassName="navigation__button--active"
            to={page}
        >
            <FontAwesomeIcon icon={icon} size="lg" />
        </NavLink>
    );
}

export function Navigation({ lastGallery }: { lastGallery: string }) {
    return (
        <div>
            <NavButton page="/" icon={faImages} />
            <NavButton page="/galleries/create" icon={faUpload} />
            <NavButton page="/texts" icon={faBook} />
            <NavButton page="/videos" icon={faVideo} />
        </div>
    );
}
