import * as t from "io-ts";

export const VideoSchema = t.strict({
    name: t.string,
    video: t.string,
    created_at: t.string,
    updated_at: t.string,
    _id: t.string,
});

export interface VideoInterface extends t.TypeOf<typeof VideoSchema> {}

export class Video implements VideoInterface {
    static fromData(data: VideoInterface): Video {
        return Object.assign(new Video(), data);
    }

    constructor(
        readonly _id: string = "",
        readonly name: string = "",
        readonly video: string = "",
        readonly created_at: string = "",
        readonly updated_at: string = ""
    ) {}

    copy(values: Partial<VideoInterface>): Video {
        return Object.assign(new Video(), this, values);
    }
}
